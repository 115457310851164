import { Component, inject } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
})
export class OnboardingComponent {
  protected authService = inject(AuthService);

  logOut() {
    this.authService.logoutUser();
  }
}
