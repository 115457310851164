<header id="header">
    <div class="navbar navbar-expand-sm navbar-main navbar-light bg-light pr-0" id="navbar">
        <div class="container p-0">
            <!-- Navbar toggler -->
            <button class="navbar-toggler navbar-toggler-right d-block d-lg-none" type="button" data-toggle="sidebar">
                <span class="navbar-toggler-icon"></span>
            </button>

            <!-- Navbar Brand -->
            <a [routerLink]="['/']" class="navbar-brand">
              <img alt="homepage" class="light-logo" src="assets/images/logo-text.svg" />
            </a>
            <ul class="nav navbar-nav d-none d-sm-flex border-left navbar-height align-items-center">
                <li class="nav-item ">
                    <a href="javascript:void(0)" class="nav-link">
                      <a href="mailto:support@atomictax.com" class="text-black-50">
                        <span class="mdi mdi-message-outline"></span>
                        Contact Support
                      </a>
                    </a>
                </li>

                <li>
                  <a
                    (click)="logOut()"
                    class="dropdown-item"
                    href="javascript:void(0)"
                    class="d-flex justify-content-end"
                  >
                    <i aria-hidden="true" class="mdi mdi-logout m-r-5 m-l-5"></i> Logout
                  </a>
                </li>
            </ul>
        </div>
    </div>
</header>

<router-outlet></router-outlet>

